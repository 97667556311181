import React from "react";
import MainView from "./components/MainView";
function App() {
  return (
    <div>
      <MainView />
    </div>
  );
}

export default App;
